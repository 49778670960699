import '../App.css';
import React from "react";
function MiddleLinks(props){
    return(
        <>
            <h1>
                <a href="#" onClick={(element) => props.setShowPage("MAP")}>Map</a>
                | <a href="#" onClick={(element) => props.setShowPage("LIST")}>List</a>
                | <a href="#" onClick={(element) => props.setShowPage("SUBMISSION")}>Submission</a>
            </h1>
        </>
    );
}

export default MiddleLinks;