import '../App.css';
import React from "react";
function AndroidApp(props){
    return (
        <>
            <table>
                <th colSpan={"2"}>
                    <h1>skate.crete.or.die in the play store now!</h1>
                    <h1><a href="https://play.google.com/store/apps/details?id=com.jaemzware.skate.crete.or.die&hl=en&gl=US">skate.crete.or.die for Android</a></h1>
                    <img src="https://seattlerules.com/media/skatecreteordie/skatecreteordieandroid.gif" alt="animated android demo" />
                </th>
                <tr>
                    <td className="left"><img src="https://seattlerules.com/media/skatecreteordie/androidv7.jpg" alt="google play beta" /></td>
                    <td className="left"></td>
                </tr>

                <tr>

                    <td className="left"><img src="https://seattlerules.com/media/skatecreteordie/googleplaybeta.jpg"
                                              alt="#skate.crete.or.die in google play store"/></td>
                    <td className="left"></td>
                </tr>
            </table>
        </>
    );
}

export default AndroidApp;