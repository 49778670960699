import '../App.css';
import React from "react";
function Footer(props){
    return(
        <>
            <h3>
                <a href="#" onClick={(element) => props.setShowPage("MAP")}>Map</a>
                | <a href="#" onClick={(element) => props.setShowPage("LIST")}>List</a>
                | <a href="#" onClick={(element) => props.setShowPage("SUBMISSION")}>Submission</a>
                | <a href="#" onClick={(element) => props.setShowPage("WELCOME")}>Welcome</a>
                | <a href="#" onClick={(element) => props.setShowPage("IOS")}>iOS</a>
                | <a href="#" onClick={(element) => props.setShowPage("ANDROID")}>Android</a>
                | <a href="https://skatecreteordie.com/skatecreteordieprivacypolicy.html">Privacy</a>
            </h3>
        </>
    );
}

export default Footer;