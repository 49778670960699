import './App.css';
import React from 'react';
import HomePage from "./components/HomePage";

function App() {
  return(
      <HomePage  />
  );
}

export default App;